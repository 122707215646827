import { doc, serverTimestamp, setDoc } from 'firebase/firestore'

import { db } from '../firebaseConfig'

// Add new user to the database
export const saveError = async (error: any) => {
  try {
    await setDoc(doc(db, 'errors'), {
      timestamp: serverTimestamp(),
      error: error,
    })
  } catch (err) {
    console.error('Error al guardar el error:', err)
  }
}
