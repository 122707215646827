import { doc, serverTimestamp, setDoc } from 'firebase/firestore'

import { db } from '../firebaseConfig'

// Add new user to the database
export const saveUser = async (userData: any) => {
  try {
    await setDoc(doc(db, 'users', userData.uid), {
      displayName: userData?.displayName,
      email: userData?.email,
      creationTime: serverTimestamp(),
      uid: userData?.uid,
    })
  } catch (err) {
    console.error('Error al guardar el usuario:', err)
  }
}
