import { addDoc, collection, updateDoc } from 'firebase/firestore'

import { db } from '../firebaseConfig'
import { saveError } from './logErrors'

// @ts-ignore
const startGame = async (gameData: any): any => {
  try {
    const docRef = await addDoc(collection(db, 'games'), {
      userId: gameData.userId,
      date: gameData.date,
      gameTime: gameData.gameTime, // This represents when the game was started
      solution: gameData.solution,
      guesses: [],
    })
    // console.log('Document added with ID: ', docRef.id)
    return docRef
  } catch (err) {
    saveError(err)
    console.error(err)
  }
}

// The ISSUE with this is that the documentRef stays outdated after the first update is done.
// So we need to update the documentRef with the current values.
// Tenemos que cambiar que en vez de pasar unicamente el documentRef entero pasar un objecto con el document ref y otro con la data
const updateGame = async (
  updatedGuess: any,
  gameData: any,
  documentRef: any
) => {
  // console.log('Document ref updated: ', documentRef)
  // console.log('Document data updated: ', gameData)
  try {
    const data = gameData
    data.guesses.push(updatedGuess)
    // console.log(data)
    await updateDoc(documentRef.ref ? documentRef.ref : documentRef, {
      guesses: data.guesses,
    })

    // console.log('Game updated successfully')
    return data
  } catch (err) {
    console.error(err)
    saveError(err)
  }
}

const uploadFinishedGameData = (
  price: string,
  seconds: number,
  documentRef: any
) => {
  try {
    updateDoc(documentRef.ref ? documentRef.ref : documentRef, {
      price: price,
      gameDuration: seconds,
      redeemed: false,
    })
  } catch (err) {
    saveError(err)
    console.error(err)
  }
}

const updatePrizeRedeemed = (documentRef: any) => {
  try {
    updateDoc(documentRef.ref ? documentRef.ref : documentRef, {
      redeemed: true,
    })
  } catch (err) {
    saveError(err)
    console.error(err)
  }
}

export { startGame, updateGame, uploadFinishedGameData, updatePrizeRedeemed }
