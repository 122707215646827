import React from 'react'
import { FC, useState } from 'react'

type ForgetPasswordProps = {
  handleResetPassword: (email: string) => void
}

const ForgetPassword: FC<ForgetPasswordProps> = ({ handleResetPassword }) => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState<string | null>(null)

  const resetPassword = () => {
    handleResetPassword(email)
    setMessage('Le enviamos un email para que cambie su contraseña')
  }
  return (
    <div>
      <label htmlFor="email" className="block font-medium">
        Email
      </label>
      <input
        type="email"
        id="email"
        autoComplete="email"
        value={email}
        required
        onChange={(e) => setEmail(e.target.value)}
        className="w-full rounded border px-3 py-1.5 text-black focus:outline-none focus:ring focus:ring-opacity-50"
        placeholder="Ingrese su email"
      />
      <div className="flex justify-center pt-3">
        <button
          type="submit"
          className="rounded bg-blue-500 px-3 py-1 font-medium text-white hover:bg-blue-600 focus:outline-none"
          onClick={() => resetPassword()}
        >
          Restaurar contraseña
        </button>
      </div>
      <p className="pt-3 text-xs text-green-500">{message}</p>
    </div>
  )
}

export default ForgetPassword
