import { doc, getDoc } from 'firebase/firestore'

import { db } from '../firebaseConfig'

// Add a new document with a generated id.

export const userInfomationCompleted = async (
  userData: any
): Promise<boolean> => {
  try {
    const docRef = doc(db, 'users', userData.uid)
    const docSnap = (await getDoc(docRef)).data()

    if (docSnap?.firstName) {
      return true
    } else {
      return false
    }
  } catch (error) {
    return false
  }
}
