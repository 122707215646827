import React, { useEffect, useState } from 'react'

import { useAlert } from '../../context/AlertContext'
import { userInfomationCompleted } from '../../firebase/isUserInformationCompleted'
import { updateUserInformation } from '../../firebase/updateUser'
import useAuth from '../../hook/auth'
import { BaseUserInformation } from './BaseUserInformation'

type Props = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleClose: () => void
  isHardMode: boolean
  handleHardMode: Function
  isDarkMode: boolean
  handleDarkMode: Function
  isHighContrastMode: boolean
  handleHighContrastMode: Function
}

export const UserInformationModal = ({
  isOpen,
  setIsOpen,
  handleClose,
  isHardMode,
  handleHardMode,
  isDarkMode,
  handleDarkMode,
  isHighContrastMode,
  handleHighContrastMode,
}: Props) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [fecha, setFecha] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isUpdating, setIsUpdating] = useState(false)
  const auth = useAuth()
  const { user } = auth
  const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
    useAlert()

  const handleUpdateUserInformation = async () => {
    if (
      firstName.length === 0 ||
      lastName.length === 0 ||
      fecha.length === 0 ||
      phoneNumber.length === 0
    ) {
      showErrorAlert('Porfavor complete todos los campos', {
        persist: false,
        delayMs: 300,
        durationMs: 4000,
      })
    } else {
      try {
        // setIsLoading(true);
        setIsUpdating(true)
        const userData = {
          firstName: firstName,
          lastName: lastName,
          dateOfBirth: fecha,
          phoneNumber: phoneNumber,
        }
        const updateUser = await updateUserInformation(user.uid, userData)
        if (updateUser === 'success') {
          // setVisible(false);
          // console.success("Usuario actualizado correctamente");
          const delayMs = 100
          showSuccessAlert('Gracias', {
            delayMs,
            durationMs: 1500,
          })
        } else {
          // console.error("Ha ocurrido un error");
        }
      } catch (err) {
        console.log(err)
      } finally {
        handleClose()
        // setIsOpen(false)
        setIsUpdating(false)
      }
    }
  }

  useEffect(() => {
    let formattedFecha = fecha

    // Add hyphen after the first two characters if it doesn't already exist
    if (formattedFecha.length >= 2 && formattedFecha.charAt(2) !== '-') {
      formattedFecha =
        formattedFecha.substring(0, 2) + '-' + formattedFecha.substring(2)
    }

    // Add hyphen after the next two characters if it doesn't already exist
    if (formattedFecha.length >= 5 && formattedFecha.charAt(5) !== '-') {
      formattedFecha =
        formattedFecha.substring(0, 5) + '-' + formattedFecha.substring(5)
    }
    setFecha(formattedFecha)
  }, [fecha])

  useEffect(() => {
    const verifyUserInfo = async () => {
      const userInfoCompleted = await userInfomationCompleted(user)
      if (!userInfoCompleted) {
        setIsOpen(true)
      }
    }
    verifyUserInfo()
  }, [])

  return (
    <BaseUserInformation title="Ya casi estas listo!" isOpen={isOpen}>
      <div className=" w-[100%] ">
        <p className="self-center  text-sm text-neutral-800 dark:text-neutral-300">
          Completa la siguiente información para empezar a jugar y disfrutar de
          todos los premios
        </p>
        <div className="mt-2 flex w-[100%] flex-col   ">
          <p className="mt-2 self-start px-5 text-neutral-800  dark:text-white">
            Nombre:
          </p>
          <div className="mt-1 flex h-10 w-[90%] flex-row  self-center ">
            <div className="relative flex h-[100%] w-[100%] justify-center self-center rounded-md  border border-gray-400 shadow-md shadow-neutral-700  dark:border-gray-200 dark:bg-neutral-800   dark:shadow-lg">
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                // onKeyDown={(e) => {
                //     if (e.key === "Enter" && !e.shiftKey) {
                //     }
                // }}
                placeholder="..."
                spellCheck={false}
                className="w-[90%]  self-center focus:outline-none dark:bg-neutral-800 dark:text-white"
              />
            </div>
          </div>
          <p className="mt-2 self-start px-5 text-neutral-800  dark:text-white">
            Apellido:
          </p>
          <div className="mt-1 flex h-10 w-[90%]  flex-row justify-between self-center ">
            <div className="relative flex h-[100%] w-[100%] justify-center self-center rounded-md  border border-gray-400 shadow-md shadow-neutral-700  dark:border-gray-200 dark:bg-neutral-800   dark:shadow-lg">
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                // onKeyDown={(e) => {
                //     if (e.key === "Enter" && !e.shiftKey) {
                //     }
                // }}
                placeholder="..."
                spellCheck={false}
                className="w-[90%]  self-center focus:outline-none dark:bg-neutral-800 dark:text-white"
              />
            </div>
          </div>
          <p className="mt-2 self-start px-5 text-neutral-800  dark:text-white">
            Fecha de Nacimiento:
          </p>
          <div className="mt-1 flex h-10 w-[90%] flex-row  self-center">
            <div className="relative flex h-[100%] w-[100%] justify-center self-center rounded-md  border border-gray-400 shadow-md shadow-neutral-700   dark:border-gray-200 dark:bg-neutral-800   dark:shadow-lg">
              <input
                value={fecha}
                onChange={(e) => {
                  if (fecha.length < 10) {
                    setFecha(e.target.value)
                  } else {
                    setFecha('')
                  }
                }}
                // onKeyDown={(e) => {
                //     if (e.key === "Enter" && !e.shiftKey) {
                //     }
                // }}
                placeholder="11-11-1999"
                spellCheck={false}
                className="w-[90%] self-center  focus:outline-none dark:bg-neutral-800 dark:text-white"
              />
            </div>
          </div>
          <p className="mt-2 self-start px-5 text-neutral-800  dark:text-white">
            Numero de telefono:
          </p>
          <div className="mt-1 flex h-10 w-[90%]  flex-row justify-between self-center ">
            <div className="relative flex h-[100%] w-[100%] justify-center self-center rounded-md  border border-gray-400 shadow-md shadow-neutral-700   dark:border-gray-200 dark:bg-neutral-800   dark:shadow-lg">
              <input
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                // onKeyDown={(e) => {
                //     if (e.key === "Enter" && !e.shiftKey) {
                //     }
                // }}
                placeholder="3511234567"
                spellCheck={false}
                className="w-[90%]  self-center focus:outline-none dark:bg-neutral-800 dark:text-white"
              />
            </div>
          </div>
        </div>
        <div className="mt-10 flex h-10 w-[100%] justify-center self-center ">
          <button
            className={` flex h-[100%] w-[50%] justify-center self-center rounded-md bg-neutral-600 dark:bg-neutral-100 ${
              isUpdating ? 'cursor-wait' : 'hover:cursor-pointer'
            }`}
            onClick={() => handleUpdateUserInformation()}
            disabled={isUpdating}
          >
            {isUpdating ? (
              <p className="self-center font-medium text-white dark:text-black">
                ...
              </p>
            ) : (
              <p className="self-center font-medium text-white dark:text-black">
                Comenzar
              </p>
            )}
          </button>
        </div>
      </div>
    </BaseUserInformation>
  )
}
