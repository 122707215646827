export const WORDS = [
  'sushi',
  'world',
  'pizza',
  'ramen',
  'amigo',
  'cenar',
  'vivir',
  'vamos',
  'ahora',
  'estoy',
  'tengo',
  'estas',
  'puedo',
  'tiene',
  'bueno',
  'hacer',
  'todos',
  'puede',
  'sabes',
  'quien',
  'nunca',
  'donde',
  'favor',
  'mejor',
  'estan',
  'usted',
  'mucho',
  'hasta',
  'sobre',
  'decir',
  'cosas',
  'antes',
  'estar',
  'noche',
  'nadie',
  'padre',
  'gente',
  'mismo',
  'hecho',
  'ellos',
  'claro',
  'lugar',
  'mundo',
  'desde',
  'fuera',
  'tener',
  'crees',
  'buena',
  'gusta',
  'nuevo',
  'habia',
  'menos',
  'tenia',
  'madre',
  'luego',
  'todas',
  'mujer',
  'visto',
  'haces',
  'tarde',
  'parte',
  'haber',
  'saber',
  'veces',
  'tanto',
  'razon',
  'quiza',
  'estos',
  'salir',
  'hemos',
  'chica',
  'entre',
  'algun',
  'serio',
  'somos',
  'pense',
  'debes',
  'forma',
  'chico',
  'dicho',
  'nueva',
  'sabia',
  'ayuda',
  'hacia',
  'miedo',
  'adios',
  'poder',
  'seria',
  'viejo',
  'manos',
  'pasar',
  'viene',
  'horas',
  'listo',
  'unico',
  'cerca',
  'otros',
  'sigue',
  'habla',
  'feliz',
  'llama',
  'venir',
  'morir',
  'atras',
  'dices',
  'abajo',
  'dejar',
  'tomar',
  'justo',
  'juego',
  'matar',
  'cinco',
  'dicen',
  'clase',
  'pueda',
  'igual',
  'venga',
  'creer',
  'saben',
  'hagas',
  'comer',
  'punto',
  'misma',
  'queda',
  'hijos',
  'mayor',
  'hacen',
  'medio',
  'basta',
  'meses',
  'facil',
  'final',
  'lista',
  'trata',
  'armas',
  'podia',
  'perro',
  'fuego',
  'murio',
  'verte',
  'culpa',
  'veras',
  'jugar',
  'joven',
  'tenga',
  'unica',
  'lejos',
  'digas',
  'vemos',
  'trato',
  'jamas',
  'falta',
  'lleva',
  'gusto',
  'coche',
  'mucha',
  'cielo',
  'habra',
  'orden',
  'deben',
  'libro',
  'calle',

  'libre',
  'viste',
  'llamo',
  'sueno',
  'viaje',

  'resto',
  'avion',

  'negro',
  'miren',
  'haria',
  'acaba',
  'otras',
  'dolor',
  'demas',
  'poner',
  'tonto',
  'campo',
  'acabo',
  'sitio',
  'verlo',
  'error',
  'llego',
  'mente',
  'novia',
  'suena',

  'hazlo',
  'grupo',
  'perra',
  'traje',

  'largo',
  'entra',
  'linea',
  'barco',
  'ganar',
  'mitad',
  'lindo',
  'pagar',
  'fotos',
  'pobre',
  'tipos',
  'darle',
  'amiga',
  'vista',
  'salvo',
  'hotel',
  'senal',
  'llevo',
  'ellas',
  'honor',
  'corre',

  'hablo',
  'media',
  'linda',
  'norte',
  'busca',
  'siete',
  'llave',
  'santo',
  'irnos',
  'vayas',
  'salio',
  'pelea',
  'banco',
  'calma',
  'creen',
  'novio',
  'decia',
  'deseo',
  'carne',
  'vidas',
  'jesus',
  'creia',
  'carta',
  'estes',
  'david',
  'quise',
  'ambos',
  'dulce',
  'vayan',
  'vuelo',
  'haras',
  'junto',
  'diria',
  'suelo',
  'llega',
  'prisa',
  'segun',
  'broma',
  'sacar',
  'papel',
  'bajar',
  'socio',

  'dando',
  'larga',
  'solia',
  'solos',
  'tanta',
  'oeste',
  'paris',
  'carga',
  'banda',
  'dejas',
  'nueve',
  'llena',

  'dejen',
  'reloj',
  'nariz',
  'vengo',

  'gordo',
  'ayude',
  'locos',
  'cinta',
  'desea',
  'leyes',
  'arbol',
  'hable',
  'malas',
  'joder',
  'lleve',
  'ruido',

  'lider',
  'haran',
  'ponte',
  'bella',

  'costa',
  'debio',

  'podre',
  'total',
  'lucha',
  'leche',
  'diste',

  'ponga',
  'lados',
  'trajo',
  'deber',
  'debil',
  'quedo',
  'quiso',
  'carro',
  'dedos',
  'sigan',

  'turno',

  'quede',
  'playa',
  'primo',
  'oiste',
  'silla',
  'envio',

  'abran',
  'senti',
  'casas',
  'mueve',
  'video',
  'yendo',
  'vivos',
  'grave',
  'pecho',
  'sepas',
  'modos',

  'apoyo',
  'toque',

  'hayan',
  'marca',
  'serie',
  'paseo',
  'balas',

  'negra',
  'pulso',
  'bordo',
  'epoca',
  'entro',
  'pesar',
  'llame',
  'cerdo',
  'busco',
  'tumba',
  'china',
  'datos',
  'torre',
  'robar',
  'malos',
  'techo',
  'genio',
  'ideas',

  'menor',
  'droga',

  'mires',
  'huele',
  'pocos',
  'angel',
  'local',

  'vives',
  'virus',

  'nieve',
  'fumar',
  'vimos',
  'reino',
  'clave',
  'plata',
  'magia',
  'humor',
  'autos',

  'curso',
  'canal',
  'echar',

  'corto',
  'cable',

  'conto',
  'siglo',

  'andar',
  'pasan',
  'troya',
  'diles',
  'coger',
  'lunes',
  'tarea',
  'corta',
  'truco',
  'pollo',
  'tonta',
  'pongo',
  'sucio',
  'pasos',
  'guste',
  'polvo',
  'pones',
  'firma',

  'firme',
  'flota',
  'queso',

  'muero',
  'viven',

  'guapo',

  'ricos',
  'tomen',
  'crear',
  'extra',
  'fuese',
  'actor',
  'naves',
  'meter',
  'dejan',
  'vacio',
  'salon',
  'motor',
  'hazme',
  'bravo',
  'regla',
  'cuida',
  'ponen',

  'bruja',
  'vegas',

  'tunel',
  'copia',

  'legal',
  'solas',
  'chino',
  'arena',
  'casos',
  'miras',
  'seres',
  'disco',
  'trate',
  'daria',
  'placa',
  'notas',
  'creas',
  'veran',
  'quita',
  'museo',
  'aviso',
  'leido',
  'tirar',
  'traes',

  'rumbo',
  'muera',
  'fecha',
  'digan',
  'maten',
  'julio',
  'ciego',
  'super',

  'sabra',
  'pocas',
  'volvi',
  'clima',
  'adoro',
  'suave',
  'bolso',

  'vivia',
  'juega',
  'acabe',
  'guapa',
  'boton',
  'ruego',
  'nadar',
  'mover',
  'conde',

  'celda',
  'amaba',
  'nacio',
  'manda',
  'salgo',
  'metas',

  'burro',

  'grial',
  'usado',
  'cajas',
  'matan',
  'pieza',
  'salen',
  'junta',
  'caido',
  'ducha',
  'venia',
  'pagan',
  'ambas',
  'dudas',
  'ritmo',
  'venta',
  'almas',
  'kilos',
  'serlo',
  'plato',
  'bolas',
  'falso',
  'mando',

  'damas',
  'robot',
  'muere',
  'trago',
  'reina',
  'lleno',
  'valor',
  'heroe',
  'capaz',
  'verme',
  'beber',
  'darte',
  'abrir',
  'golpe',
  'tocar',
  'ganas',
  'pedir',
  'pista',
  'sirve',
  'doble',
  'esten',
  'debia',

  'color',
  'aquel',
  'fondo',

  'verde',
  'traer',
  'pidio',
  'exito',
  'hielo',
  'podra',
  'oigan',
  'comun',
  'calor',
  'pared',
  'subir',
]
