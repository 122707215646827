import React from 'react'
import { FC, FormEvent, useEffect, useState } from 'react'

import ForgetPassword from './ForgetPassword'

type EmailPasswordProps = {
  setEmailPasswordLogin: (value: boolean) => void
  handleCreateAccount: (
    email: string,
    password: string,
    fullName: string
  ) => void
  handleSignIn: (email: string, password: string) => void
  handleResetPassword: (email: string) => void
  error: any
}

const EmailPassword: FC<EmailPasswordProps> = ({
  setEmailPasswordLogin,
  handleCreateAccount,
  handleSignIn,
  handleResetPassword,
  error,
}) => {
  const [hasAccount, setHasAccount] = useState(true)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [timeoutIdPassword, setTimeoutIdPassword] =
    useState<NodeJS.Timeout | null>(null)
  const [repeatPassword, setRepeatPassword] = useState('')
  const [repeatPasswordError, setRepeatPasswordError] = useState('')
  const [timeoutIdRepeatPassword, setTimeoutIdRepeatPassword] =
    useState<NodeJS.Timeout | null>(null)
  const [fullName, setFullName] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [forgetPassword, setForgetPassword] = useState(false)

  useEffect(() => {
    if (error) {
      switch (true) {
        case error.message.includes('auth/wrong-password'):
          setErrorMessage('Las credenciales son incorrectas')
          break
        case error.message.includes('user-not-found'):
          setErrorMessage('No existe ningún usuario con ese email')
          break
        case error.message.includes(
          'Access to this account has been temporarily disabled'
        ):
          setErrorMessage(
            'No puede iniciar sesión momentaneamente, intente más tarde'
          )
          break
        case error.message.includes('auth/email-already-in-use'):
          setErrorMessage('Ya existe una cuenta con ese email')
          break
        default:
          setErrorMessage(null)
      }
    } else {
      setErrorMessage(null)
    }
  }, [error])

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (hasAccount) handleSignIn(email, password)
    else handleCreateAccount(email, password, fullName)
  }

  const validatePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordError('')
    const newPassword = event.target.value
    setPassword(newPassword)
    const hasCapitalLetter = /[A-Z]/.test(newPassword)
    const hasNumber = /\d/.test(newPassword)
    const isMinimumLength = newPassword.length >= 8
    if (timeoutIdPassword) {
      clearTimeout(timeoutIdPassword)
    }
    if (!hasCapitalLetter || !hasNumber || !isMinimumLength) {
      const newTimeoutId = setTimeout(() => {
        setPasswordError(
          'La contraseña debe tener al menos una letra mayúscula, un número y 8 caracteres.'
        )
      }, 2000)
      setTimeoutIdPassword(newTimeoutId)
    }
  }

  const validateRepeatPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRepeatPasswordError('')
    const repeatedPassword = event.target.value
    setRepeatPassword(repeatedPassword)
    if (timeoutIdRepeatPassword) {
      clearTimeout(timeoutIdRepeatPassword)
    }
    if (password !== repeatedPassword) {
      const newTimeoutId = setTimeout(() => {
        setRepeatPasswordError('Las contraseñas deben coincidir.')
      }, 2000)
      setTimeoutIdRepeatPassword(newTimeoutId)
    }
  }

  return (
    <>
      {forgetPassword ? (
        <ForgetPassword handleResetPassword={handleResetPassword} />
      ) : (
        <form className="space-y-2" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="email"
              className="block font-medium text-neutral-200"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              autoComplete="email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
              className="w-full rounded border px-3 py-1.5 text-black focus:outline-none focus:ring focus:ring-opacity-50"
              placeholder="Ingrese su email"
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block font-medium text-neutral-200"
            >
              Contraseña
            </label>
            <input
              type="password"
              id="password"
              value={password}
              required
              onChange={(e) => validatePassword(e)}
              className="w-full rounded border px-3 py-1.5 text-black focus:outline-none focus:ring focus:ring-opacity-50"
              placeholder="Ingrese su contraseña"
            />
            {passwordError && !hasAccount && (
              <p className="text-xs text-red-500">{passwordError}</p>
            )}
          </div>
          {!hasAccount && (
            <>
              <div>
                <label
                  htmlFor="password"
                  className="block font-medium text-neutral-200"
                >
                  Repetir Contraseña
                </label>
                <input
                  type="password"
                  id="repeatPassword"
                  value={repeatPassword}
                  required={!hasAccount}
                  onChange={(e) => validateRepeatPassword(e)}
                  className="w-full rounded border px-3 py-1.5 text-black focus:outline-none focus:ring focus:ring-opacity-50"
                  placeholder="Ingrese su contraseña"
                />
                {repeatPasswordError && (
                  <p className="text-xs text-red-500">{repeatPasswordError}</p>
                )}
              </div>
              <div>
                <label
                  htmlFor="fullName"
                  className="block font-medium text-neutral-200"
                >
                  Nombre Completo
                </label>
                <input
                  type="name"
                  id="name"
                  autoComplete="name"
                  value={fullName}
                  required={!hasAccount}
                  onChange={(e) => setFullName(e.target.value)}
                  className="w-full rounded border px-3 py-1.5 text-black focus:outline-none focus:ring focus:ring-opacity-50"
                  placeholder="Ingrese su nombre completo"
                />
              </div>
            </>
          )}
          <div className="flex justify-between pt-2">
            <button
              type="button"
              className="mr-2 w-1/2 rounded bg-gray-500 py-1.5 font-medium text-white hover:bg-gray-600 focus:outline-none"
              onClick={() => {
                setEmailPasswordLogin(false)
              }}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="ml-2 w-1/2 rounded bg-blue-500 py-1.5 font-medium text-white hover:bg-blue-600 focus:outline-none"
            >
              {hasAccount ? 'Entrar' : 'Crear'}
            </button>
          </div>
          {errorMessage && (
            <p className="text-xs text-red-500">{errorMessage}</p>
          )}
          <div className="flex justify-between pt-2">
            <a
              className="cursor-pointer text-sm text-blue-500"
              onClick={() => setHasAccount(!hasAccount)}
            >
              {hasAccount
                ? 'No tengo cuenta, quiero registrarme'
                : 'Ya tengo cuenta'}{' '}
            </a>
          </div>
        </form>
      )}

      <div className="flex justify-between pt-2">
        <a
          className="cursor-pointer text-sm text-blue-500"
          onClick={() => setForgetPassword(!forgetPassword)}
        >
          {forgetPassword ? 'Volver' : 'Olvide mi contraseña'}{' '}
        </a>
      </div>
    </>
  )
}

export default EmailPassword
