import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Como Jugar?" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Adivina la palabra en 6 intentos. Después de cada suposición, el color
        de las fichas cambiará para mostrar qué tan cerca estuvo su suposición
        de la palabra.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell isRevealing={true} isCompleted={true} value="S" />
        <Cell value="U" isCompleted={true} status="correct" />
        <Cell value="S" isCompleted={true} />
        <Cell value="H" isCompleted={true} />
        <Cell value="I" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        La letra U está en la palabra y en el lugar correcto.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="T" isCompleted={true} />
        <Cell value="E" isCompleted={true} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="L"
          status="present"
        />
        <Cell value="O" isCompleted={true} />
        <Cell value="N" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        La letra L está en la palabra pero en el lugar equivocado.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="V" isCompleted={true} />
        <Cell value="A" isCompleted={true} />
        <Cell value="G" isCompleted={true} />
        <Cell isRevealing={true} isCompleted={true} value="O" status="absent" />
        <Cell value="N" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        La letra U no está en la palabra ni en ningún lugar.
      </p>

      <p className="mt-6 text-sm italic text-gray-500 dark:text-gray-300">
        Esperamos que disfrutes del juego mientras comes el mejor sushi. Premios
        nuevos todas las semanas
        {/* <a
          href="https://github.com/cwackerfuss/react-wordle"
          className="font-bold underline"
        >
          check out the code here
        </a>{' '} */}
      </p>
    </BaseModal>
  )
}
