export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

export const WIN_MESSAGES = ['¡Buen trabajo!', 'Impresionante', '¡Bien hecho!']
export const GAME_COPIED_MESSAGE = 'Juego copiado al portapapeles'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'No hay suficientes letras'
export const WORD_NOT_FOUND_MESSAGE = 'Palabra no encontrada'
export const HARD_MODE_ALERT_MESSAGE =
  '¡El Modo Difícil solo se puede habilitar al principio!'
export const HARD_MODE_DESCRIPTION =
  'Cualquier pista revelada debe ser utilizada en intentos posteriores'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'Para mejorar la visión de color'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `La palabra era ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Debe usar ${guess} in position ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `La suposición debe contener ${letter}`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Borrar'
export const STATISTICS_TITLE = 'Estadísticas'
export const GUESS_DISTRIBUTION_TEXT = 'Distribución de Aciertos'
export const NEW_WORD_TEXT = 'Nueva palabra en'
export const SHARE_TEXT = 'Compartir'
export const SHARE_FAILURE_TEXT =
  'No se puede compartir los resultados. Esta función solo está disponible en contextos seguros (HTTPS), en algunos o todos los navegadores compatibles.'
export const MIGRATE_BUTTON_TEXT = 'Transferir'
export const MIGRATE_DESCRIPTION_TEXT =
  'Haz clic aquí para transferir tus estadísticas a un nuevo dispositivo.'
export const TOTAL_TRIES_TEXT = 'Intentos totales'
export const SUCCESS_RATE_TEXT = 'Tasa de éxito'
export const CURRENT_STREAK_TEXT = 'Racha actual'
export const BEST_STREAK_TEXT = 'Mejor racha'
export const DISCOURAGE_INAPP_BROWSER_TEXT =
  'Estás usando un navegador integrado y es posible que experimentes problemas al compartir o guardar tus resultados. Te recomendamos que uses el navegador predeterminado de tu dispositivo.'

export const DATEPICKER_TITLE = 'Elige una fecha pasada'
export const DATEPICKER_CHOOSE_TEXT = 'Elegir'
export const DATEPICKER_TODAY_TEXT = 'hoy'
export const ARCHIVE_GAMEDATE_TEXT = 'Fecha del juego'
