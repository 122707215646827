import React, { useEffect, useState } from 'react'
import Confetti from 'react-confetti'

import { updatePrizeRedeemed } from '../../firebase/game'
import { BaseWinnedModal } from './BaseWinned'

type Props = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleClose: () => void
  priceName: string
  documentRef: any
}

export const WinnedModal = ({
  isOpen,
  setIsOpen,
  handleClose,
  priceName,
  documentRef,
}: Props) => {
  const [countdown, setCountdown] = useState(10)
  const [closePrizeModal, setClosePrizeModal] = useState<number>(0)

  useEffect(() => {
    if (isOpen) {
      if (countdown > 0) {
        const timer = setInterval(() => {
          setCountdown((prevCountdown) => prevCountdown - 1)
        }, 1000)

        return () => clearInterval(timer)
      }
    }
  }, [isOpen, countdown])

  useEffect(() => {
    if (closePrizeModal === 10) {
      setIsOpen(false)
      // Fire the update doc with redemeed to true
      updatePrizeRedeemed(documentRef)
    }
  }, [closePrizeModal])

  return (
    <BaseWinnedModal title="FELICITACIONES" isOpen={isOpen}>
      <div className=" w-[100%] ">
        <p
          onClick={() => setClosePrizeModal(closePrizeModal + 1)}
          className=" self-center  text-sm text-neutral-800 dark:text-neutral-300"
        >
          !Felicidades por haber logrado adivinar la palabra! <br />
          Por tu habilidad para descubrir la palabra y la rapidez en encontrar
          la respuesta te ganaste un premio 🙌🏼 <br />
        </p>
        <p className="mt-4 self-center text-sm text-neutral-500 dark:text-neutral-300">
          Mostrale esta mensaje a tu mozo para solicitar <br /> 🥂 tu premio 🥂
        </p>
        <div className="mt-2 flex w-[100%] flex-col    ">
          <div className="flex h-[30vh] w-[90%] flex-col  justify-center self-center ">
            {countdown === 0 ? (
              <>
                <p className="lighting-effect bg-clip-text text-4xl font-bold text-transparent">
                  {priceName}
                </p>
                <Confetti
                  recycle={false}
                  // width={600}
                  // height={1000}
                  numberOfPieces={1000}
                />
              </>
            ) : (
              <>
                <p className=" bg-clip-text text-4xl font-bold text-white">
                  {countdown}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </BaseWinnedModal>
  )
}
