import moment from 'moment'

import { priceList } from './priceList'

export const getRandomNumber = (): number => {
  const preciseMoment = moment.now()
  const randomNumber = (preciseMoment % 1000000) + 1
  return randomNumber
}
const getPrice = (category: number): string => {
  const randomNumber = getRandomNumber()
  let price = ''
  if (category === 1) {
    const availablePrices = priceList[1]
    price = availablePrices[randomNumber % availablePrices.length]
  }
  if (category === 2) {
    const availablePrices = priceList[2]
    price = availablePrices[randomNumber % availablePrices.length]
  }
  if (category === 3) {
    const availablePrices = priceList[3]
    price = availablePrices[randomNumber % availablePrices.length]
  }
  if (category === 4) {
    const availablePrices = priceList[4]
    price = availablePrices[randomNumber % availablePrices.length]
  }
  return price
}

export const checkPriceWinning = (gameTime: number): string => {
  let price = ''
  // Prices under 1 minute
  if (gameTime <= 60) {
    price = getPrice(1)
  }
  // Price between 1 and 3 minutes
  if (gameTime > 60 && gameTime <= 180) {
    price = getPrice(2)
  }
  // Price between 3 and 7 minutes
  if (gameTime > 180 && gameTime <= 420) {
    price = getPrice(3)
  }
  // Price over 7 minutes
  if (gameTime > 420) {
    price = getPrice(4)
  }
  return price
}
