import { getAnalytics, logEvent } from 'firebase/analytics'
import { createContext, useContext, useState } from 'react'
import { useEffect } from 'react'

import { getUser } from '../firebase/getUser'
import { saveUser } from '../firebase/saveUser'
import { auth } from '../firebaseConfig'
import { AuthService } from '../service/AuthService'

const authContext = createContext()

export default function useAuth() {
  return useContext(authContext)
}

export const AuthProvider = (props) => {
  const [user, setUser] = useState(null)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  // We have to modify this part to access when is loading or something
  const handleSignIn = async (signInFunction, method) => {
    setLoading(true)
    try {
      const { user, error } = await signInFunction() // Using this also
      setUser(user ?? null)
      setError(error ?? '')
      if (user && !error) {
        const existsUser = getUser(user)
        if (!existsUser) saveUser(user)
        const analytics = getAnalytics()
        logEvent(analytics, 'signed-in', { method })
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleGoogleSignIn = async () => {
    await handleSignIn(AuthService.handleGoogleSignIn, 'Google')
  }

  const handleAppleSignIn = async () => {
    await handleSignIn(AuthService.handleAppleSignIn, 'Apple')
  }

  const handleMicrosoftSignIn = async () => {
    await handleSignIn(AuthService.handleMicrosoftSignIn, 'Microsoft')
  }

  const handleFacebookSignIn = async () => {
    await handleSignIn(AuthService.handleFacebookSignIn, 'Facebook')
  }

  const handleEmailPassRegister = async (email, password, name, lastName) => {
    await handleSignIn(
      () =>
        AuthService.handleEmailPassRegister(email, password, name, lastName),
      'Email'
    )
  }

  const handleEmailPassSignIn = async (email, password) => {
    await handleSignIn(
      () => AuthService.handleEmailPassSignIn(email, password),
      'Email'
    )
  }

  const handleResetPassword = async (email) => {
    AuthService.handleResetPassword(email)
  }

  const handleLogout = async () => {
    await AuthService.logOut()
    setUser(null)
  }

  const value = {
    user,
    error,
    loading,
    handleGoogleSignIn,
    handleAppleSignIn,
    handleMicrosoftSignIn,
    handleFacebookSignIn,
    handleEmailPassRegister,
    handleEmailPassSignIn,
    handleResetPassword,
    handleLogout,
  }

  useEffect(() => {
    auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser)
      setLoading(false)
    })
  }, [])

  return <authContext.Provider value={value} {...props} />
}
