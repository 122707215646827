import '../App.css'

import { ClockIcon } from '@heroicons/react/outline'
import { format } from 'date-fns'
import { default as GraphemeSplitter } from 'grapheme-splitter'
import { useEffect, useState } from 'react'
import Div100vh from 'react-div-100vh'

import { AlertContainer } from '../components/alerts/AlertContainer'
import { Grid } from '../components/grid/Grid'
import { Keyboard } from '../components/keyboard/Keyboard'
import { DatePickerModal } from '../components/modals/DatePickerModal'
import { InfoModal } from '../components/modals/InfoModal'
import { MigrateStatsModal } from '../components/modals/MigrateStatsModal'
import { SettingsModal } from '../components/modals/SettingsModal'
import { StatsModal } from '../components/modals/StatsModal'
import { UserInformationModal } from '../components/modals/UserInformation'
import { WinnedModal } from '../components/modals/WinnedModal'
import { Navbar } from '../components/navbar/Navbar'
import {
  DATE_LOCALE,
  DISCOURAGE_INAPP_BROWSERS,
  LONG_ALERT_TIME_MS,
  MAX_CHALLENGES,
  REVEAL_TIME_MS,
  WELCOME_INFO_MODAL_MS,
} from '../constants/settings'
import {
  CORRECT_WORD_MESSAGE,
  DISCOURAGE_INAPP_BROWSER_TEXT,
  GAME_COPIED_MESSAGE,
  HARD_MODE_ALERT_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  SHARE_FAILURE_TEXT,
  WIN_MESSAGES,
  WORD_NOT_FOUND_MESSAGE,
} from '../constants/strings'
import { useAlert } from '../context/AlertContext'
import { checkTodayGame } from '../firebase/checkTodayGame'
import { startGame, updateGame, uploadFinishedGameData } from '../firebase/game'
import { saveError } from '../firebase/logErrors'
import useAuth from '../hook/auth'
import { isInAppBrowser } from '../lib/browser'
import {
  getStoredIsHighContrastMode,
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  setStoredIsHighContrastMode,
} from '../lib/localStorage'
import { checkPriceWinning } from '../lib/prices'
import { addStatsForCompletedGame, loadStats } from '../lib/stats'
import {
  findFirstUnusedReveal,
  getGameDate,
  getIsLatestGame,
  isWordInWordList,
  setGameDate,
  solution,
  solutionGameDate,
  unicodeLength,
} from '../lib/words'

function Home() {
  const auth = useAuth()
  const { user } = auth
  const isLatestGame = getIsLatestGame()
  const gameDate = new Date()
  const [prefersDarkMode, setPreferesDarkMode] = useState(true)

  const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
    useAlert()
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false)
  const [isMigrateStatsModalOpen, setIsMigrateStatsModalOpen] = useState(false)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [isUserInformationModalOpen, setIsUserInformationModalOpen] =
    useState<boolean>(false)

  const [isWinnedModalOpen, setIsWinnedModalOpen] = useState(false)
  const [winnedPrice, setWinnedPrice] = useState('')
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isGameLost, setIsGameLost] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme') === 'light' ? false : prefersDarkMode
  )
  const [isHighContrastMode, setIsHighContrastMode] = useState(
    getStoredIsHighContrastMode()
  )
  const [documentRefId, setDocumentRefId] = useState<any>('')
  const [gameData, setGameData] = useState<any>('')
  const [isRevealing, setIsRevealing] = useState(false)
  const [solutionn, setSolution] = useState<string>('')
  const [guesses, setGuesses] = useState<string[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const loaded = await checkTodayGame(user)
        // console.log(loaded)

        if (loaded?.gameData.solution !== '') {
          // console.log('GAME IS ALREADY STARTED')
          setDocumentRefId(loaded?.documentId)
          setGameData(loaded?.gameData)
          if (loaded?.gameData.guesses?.length === MAX_CHALLENGES) {
            const gameWasWon = loaded.gameData.guesses.includes(
              loaded.gameData.solution as never
            )
            // console.log(gameWasWon)
            if (gameWasWon) {
              setIsGameWon(true)
              setSolution(loaded.gameData.solution)
              setGuesses(loaded.gameData.guesses)
            }
            if (
              loaded?.gameData.guesses.length === MAX_CHALLENGES &&
              !gameWasWon
            ) {
              setIsGameLost(true)
              showErrorAlert(CORRECT_WORD_MESSAGE(loaded.gameData.solution), {
                persist: true,
              })
            }
          } else {
            const gameWasWon = loaded?.gameData.guesses.includes(
              loaded.gameData.solution as never
            )
            if (gameWasWon && loaded) {
              // console.log('Aqui ando')
              setIsGameWon(true)
              setSolution(loaded.gameData.solution)
              setGuesses(loaded.gameData.guesses)
              if (!loaded.gameData.redeemed) {
                // console.log('Game is not redeemed')
                setWinnedPrice(loaded.gameData.price)
                setIsWinnedModalOpen(true)
              } else {
                // console.log('Game already redemed')
              }
            }
            // console.log(gameWasWon)
            // console.log(loaded)
            setGuesses(loaded?.gameData.guesses as string[])
            setSolution(loaded?.gameData.solution as string)
          }
        } else {
          // console.log('No game initialized\n New solution: ' + solution)
          const gameData = {
            date: gameDate,
            gameTime: new Date(),
            solution: solution,
            guesses: [],
            userId: user.uid,
          }
          // console.log(gameData)
          const newGame = await startGame(gameData)
          // console.log('Soy new game: ', newGame)
          setDocumentRefId(newGame)
          setGameData(gameData)
          setGuesses([])
          setSolution(solution)
        }
      } catch (err) {
        console.error(err)
      }
    }
    fetchData()
    return () => {
      // Clean up resources here (e.g., unsubscribe from a subscription)
    }
  }, [])

  // useEffect(() => {
  //   console.log('New solution:', solutionn)
  // }, [solutionn])

  const [stats, setStats] = useState(() => loadStats())

  const [isHardMode, setIsHardMode] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'hard'
      : false
  )

  useEffect(() => {
    // if no game state on load,
    // show the user the how-to info modal
    if (!loadGameStateFromLocalStorage(true)) {
      setTimeout(() => {
        setIsInfoModalOpen(true)
      }, WELCOME_INFO_MODAL_MS)
    }
  }, [])

  useEffect(() => {
    DISCOURAGE_INAPP_BROWSERS &&
      isInAppBrowser() &&
      showErrorAlert(DISCOURAGE_INAPP_BROWSER_TEXT, {
        persist: false,
        durationMs: 7000,
      })
  }, [showErrorAlert])

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    if (isHighContrastMode) {
      document.documentElement.classList.add('high-contrast')
    } else {
      document.documentElement.classList.remove('high-contrast')
    }
  }, [isDarkMode, isHighContrastMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  const handleHardMode = (isHard: boolean) => {
    if (guesses.length === 0 || localStorage.getItem('gameMode') === 'hard') {
      setIsHardMode(isHard)
      localStorage.setItem('gameMode', isHard ? 'hard' : 'normal')
    } else {
      showErrorAlert(HARD_MODE_ALERT_MESSAGE)
    }
  }

  const handleHighContrastMode = (isHighContrast: boolean) => {
    setIsHighContrastMode(isHighContrast)
    setStoredIsHighContrastMode(isHighContrast)
  }

  const clearCurrentRowClass = () => {
    setCurrentRowClass('')
  }

  useEffect(() => {
    saveGameStateToLocalStorage(getIsLatestGame(), { guesses, solution })
  }, [guesses])

  useEffect(() => {
    if (isGameWon) {
      // const winMessage =
      //   WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
      // const delayMs = REVEAL_TIME_MS * solutionn.length
      // showSuccessAlert(winMessage, {
      //   delayMs,
      //   onClose: () => setIsWinnedModalOpen(true),
      // })
    }

    if (isGameLost) {
      setTimeout(() => {
        setIsStatsModalOpen(true)
      }, (solutionn.length + 1) * REVEAL_TIME_MS)
    }
  }, [isGameWon, isGameLost, showSuccessAlert])

  const onChar = (value: string) => {
    if (
      unicodeLength(`${currentGuess}${value}`) <= solutionn.length &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(
      new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join('')
    )
  }

  const onEnter = async () => {
    try {
      if (isGameWon || isGameLost) {
        return
      }

      if (!(unicodeLength(currentGuess) === solutionn.length)) {
        setCurrentRowClass('jiggle')
        return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
          onClose: clearCurrentRowClass,
        })
      }

      if (!isWordInWordList(currentGuess)) {
        setCurrentRowClass('jiggle')
        return showErrorAlert(WORD_NOT_FOUND_MESSAGE, {
          onClose: clearCurrentRowClass,
        })
      }

      // enforce hard mode - all guesses must contain all previously revealed letters
      if (isHardMode) {
        const firstMissingReveal = findFirstUnusedReveal(currentGuess, guesses)
        if (firstMissingReveal) {
          setCurrentRowClass('jiggle')
          return showErrorAlert(firstMissingReveal, {
            onClose: clearCurrentRowClass,
          })
        }
      }

      setIsRevealing(true)
      // turn this back off after all
      // chars have been revealed
      setTimeout(() => {
        setIsRevealing(false)
      }, REVEAL_TIME_MS * solutionn.length)

      const isWinningWord = (word: string) => {
        return solutionn === word
      }
      const winningWord = isWinningWord(currentGuess)

      if (
        unicodeLength(currentGuess) === solutionn.length &&
        guesses.length < MAX_CHALLENGES &&
        !isGameWon
      ) {
        let currentWord = currentGuess
        setCurrentGuess('')
        setGuesses([...guesses, currentWord])
        const handleUpdate = await updateGame(
          currentWord,
          gameData,
          documentRefId
        )
        // console.log('Response: ', handleUpdate)
        setGameData(handleUpdate)
        // console.log('GameDataState: ', gameData)
        // setDocumentRefId(handleUpdate)

        if (winningWord) {
          if (isLatestGame) {
            setStats(addStatsForCompletedGame(stats, guesses.length))
          }
          // add here to update game time and see prize in case of winning
          let finishTime = new Date()
          let timestamp1
          let timestamp2
          // This is the most Bugght code
          // We believe that if its AM its the uncommented way and if its PM its the Commented way
          if (gameData?.gameTime?.seconds) {
            const milliseconds =
              gameData.gameTime.seconds * 1000 +
              Math.floor(gameData.gameTime.nanoseconds / 1000000)
            const timestamp = new Date(milliseconds)
            timestamp1 = timestamp.getTime()
            timestamp2 = finishTime.getTime()
          } else {
            timestamp1 = gameData.gameTime.getTime()
            timestamp2 = finishTime.getTime()
          }

          // @ts-ignore
          const overallGameDiff = (Math.abs(timestamp2 - timestamp1) / 1000)
            .toString()
            .split('.')
          // console.log(overallGameDiff)
          const overallGameTime = parseInt(overallGameDiff[0])

          // console.log('Started Date: ', gameData.gameTime)
          // console.log('Finish Date: ', finishTime)
          // console.log('Overall time in seconds: ', overallGameTime)
          const price = checkPriceWinning(overallGameTime)
          setWinnedPrice(price)
          uploadFinishedGameData(price, overallGameTime, documentRefId)
          const winMessage =
            WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
          const delayMs = REVEAL_TIME_MS * solutionn.length

          showSuccessAlert(winMessage, {
            delayMs,
            onClose: () => setIsWinnedModalOpen(true),
          })
          return
        }

        if (guesses.length === MAX_CHALLENGES - 1) {
          if (isLatestGame) {
            setStats(addStatsForCompletedGame(stats, guesses.length + 1))
          }
          setIsGameLost(true)
          showErrorAlert(CORRECT_WORD_MESSAGE(solutionn), {
            persist: true,
            delayMs: REVEAL_TIME_MS * solutionn.length + 1,
          })
        }
      }
    } catch (error) {
      saveError(error)
    }
  }

  return (
    <Div100vh>
      <div className="flex h-full flex-col bg-white dark:bg-neutral-800">
        <Navbar
          setIsInfoModalOpen={setIsInfoModalOpen}
          setIsStatsModalOpen={setIsStatsModalOpen}
          setIsDatePickerModalOpen={setIsDatePickerModalOpen}
          setIsSettingsModalOpen={setIsSettingsModalOpen}
          isDarkMode={isDarkMode}
        />

        {!isLatestGame && (
          <div className="flex items-center justify-center">
            <ClockIcon className="h-6 w-6 stroke-gray-600 dark:stroke-gray-300" />
            <p className="text-base text-gray-600 dark:text-gray-300">
              {format(gameDate, 'd MMMM yyyy', { locale: DATE_LOCALE })}
            </p>
          </div>
        )}

        <div className="mx-auto flex w-full grow flex-col px-1 pt-2 pb-8 sm:px-6 md:max-w-7xl lg:px-8 short:pb-2 short:pt-2">
          <div className="flex grow flex-col justify-center  pb-6 short:pb-2">
            <Grid
              solution={solutionn}
              guesses={guesses}
              currentGuess={currentGuess}
              isRevealing={isRevealing}
              currentRowClassName={currentRowClass}
            />
            <div className="felx-row flex justify-center self-center">
              {isDarkMode ? (
                <img
                  src="/sushiWorldWhite.png"
                  className=" xxxshort:mb-[0%] mr-4 w-[30%] self-center object-cover lg:w-[12%] xshort:-mb-[10%]"
                  alt="Pluma Icon"
                />
              ) : (
                <img
                  src="/sushiWorldBlack.png"
                  className=" xxxshort:mb-[0%] mr-4 w-[30%] self-center object-cover lg:w-[10%] xshort:-mb-[10%]"
                  alt="Pluma Icon"
                />
              )}

              <img
                src="/stella-artois.png"
                className=" xxxshort:mb-[0%] ml-4 w-[22%] self-center object-cover lg:w-[10%] xshort:-mb-[10%]"
                alt="Pluma Icon"
              />
            </div>
          </div>
          <Keyboard
            onChar={onChar}
            onDelete={onDelete}
            onEnter={onEnter}
            isDisabled={isUserInformationModalOpen}
            solution={solutionn}
            guesses={guesses}
            isRevealing={isRevealing}
          />
          <InfoModal
            isOpen={isInfoModalOpen}
            handleClose={() => setIsInfoModalOpen(false)}
          />
          <StatsModal
            isOpen={isStatsModalOpen}
            handleClose={() => setIsStatsModalOpen(false)}
            solution={solutionn}
            guesses={guesses}
            gameStats={stats}
            isLatestGame={isLatestGame}
            isGameLost={isGameLost}
            isGameWon={isGameWon}
            handleShareToClipboard={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
            handleShareFailure={() =>
              showErrorAlert(SHARE_FAILURE_TEXT, {
                durationMs: LONG_ALERT_TIME_MS,
              })
            }
            handleMigrateStatsButton={() => {
              setIsStatsModalOpen(false)
              setIsMigrateStatsModalOpen(true)
            }}
            isHardMode={isHardMode}
            isDarkMode={isDarkMode}
            isHighContrastMode={isHighContrastMode}
            numberOfGuessesMade={guesses.length}
          />
          <DatePickerModal
            isOpen={isDatePickerModalOpen}
            initialDate={solutionGameDate}
            handleSelectDate={(d) => {
              setIsDatePickerModalOpen(false)
              setGameDate(d)
            }}
            handleClose={() => setIsDatePickerModalOpen(false)}
          />
          <MigrateStatsModal
            isOpen={isMigrateStatsModalOpen}
            handleClose={() => setIsMigrateStatsModalOpen(false)}
          />
          <SettingsModal
            isOpen={isSettingsModalOpen}
            handleClose={() => setIsSettingsModalOpen(false)}
            isHardMode={isHardMode}
            handleHardMode={handleHardMode}
            isDarkMode={isDarkMode}
            handleDarkMode={handleDarkMode}
            isHighContrastMode={isHighContrastMode}
            handleHighContrastMode={handleHighContrastMode}
          />
          <UserInformationModal
            isOpen={isUserInformationModalOpen}
            setIsOpen={setIsUserInformationModalOpen}
            handleClose={() => {
              setIsUserInformationModalOpen(false)

              setTimeout(() => {
                setIsInfoModalOpen(true)
              }, 1250)
            }}
            isHardMode={isHardMode}
            handleHardMode={handleHardMode}
            isDarkMode={isDarkMode}
            handleDarkMode={handleDarkMode}
            isHighContrastMode={isHighContrastMode}
            handleHighContrastMode={handleHighContrastMode}
          />
          <WinnedModal
            isOpen={isWinnedModalOpen}
            setIsOpen={() => setIsWinnedModalOpen(false)}
            handleClose={() => setIsUserInformationModalOpen(false)}
            priceName={winnedPrice}
            documentRef={documentRefId}
          />

          <AlertContainer />
        </div>
      </div>
    </Div100vh>
  )
}

export default Home
