import '../App.css'

function LoadingComponent() {
  return (
    <div className="flex h-screen w-screen justify-center bg-neutral-800">
      <img
        src="/sushiWord.png"
        className=" w-[60%] self-center object-cover md:w-[25%] lg:w-[10%]"
        alt="Pluma Icon"
      />
    </div>
  )
}

export default LoadingComponent
