import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'

type LoginButtonProps = {
  providerName: string
  handleSignIn: () => void
  background: string
  color: string
  widestButtonWidth: number
  setWidestButtonWidth: Dispatch<SetStateAction<number>>
  isLoading: boolean
}

const LoginButton: FC<LoginButtonProps> = ({
  providerName,
  handleSignIn,
  background,
  color,
  widestButtonWidth,
  setWidestButtonWidth,
  isLoading,
}) => {
  const buttonRef = useRef(null)

  useEffect(() => {
    if (buttonRef.current) {
      //@ts-ignore
      const width = buttonRef.current.offsetWidth
      if (width > widestButtonWidth) {
        setWidestButtonWidth(width)
      }
    }
  }, [widestButtonWidth, setWidestButtonWidth])

  return (
    <div className="py-1.5">
      <button
        ref={buttonRef}
        className={`${background} ${color} flex items-center rounded border border-gray-900 py-2 px-4 font-semibold text-black ${
          isLoading ? 'bg-opacity-10' : ''
        }`}
        style={{ minWidth: `${widestButtonWidth}px` }}
        onClick={() => handleSignIn()}
        disabled={isLoading}
      >
        <img
          src={`/login/${providerName}.svg`}
          width={15}
          height={15}
          alt={`Ingresar con ${providerName}`}
          className="mr-2"
        />
        {isLoading ? (
          <div className="flex w-[200px] justify-center self-center">
            <img
              src={`/loadingCircle.png`}
              width={20}
              height={20}
              className="mr-4 animate-spin self-center"
            />
          </div>
        ) : (
          <p className={`ml-2 ${color}`}>
            Continuar con{' '}
            {providerName[0].toUpperCase() + providerName.slice(1)}
          </p>
        )}
      </button>
    </div>
  )
}

export default LoginButton
