import { Route, Routes } from 'react-router-dom'

import { AuthProvider } from './hook/auth'
import { ProtectedRoute, PublicRoute } from './hook/route'
import Auth from './routes/Auth'
import Home from './routes/Home'

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/auth"
          element={
            <PublicRoute>
              <Auth />
            </PublicRoute>
          }
        />
      </Routes>
    </AuthProvider>
  )
}

export default App
