import { collection, getDocs, limit, query, where } from 'firebase/firestore'

import { db } from '../firebaseConfig'

interface gameDataProps {
  date: Date | undefined
  gameTime: number | undefined
  solution: string | ''
  guesses: string[]
  price: string | ''
  userId: string | undefined
  redeemed: boolean | undefined
}

export const checkTodayGame = async (user: any) => {
  //   const { user } = auth
  const today = new Date()
  const twenyFourHoursAgo = new Date()
  twenyFourHoursAgo.setHours(today.getHours() - 24)
  // console.log('The current date is: ' + today)
  // today.setHours(0, 0, 0, 0)
  // console.log('Params: ', user.uid, today)

  const gamesCollection = collection(db, 'games')
  const q = query(
    gamesCollection,
    where('userId', '==', user.uid),
    where('date', '>=', twenyFourHoursAgo),
    limit(1)
  )

  try {
    // const docRef = doc(db, 'games', user.uid)
    const querySnap = await getDocs(q)

    if (!querySnap.empty) {
      const docSnapshot = querySnap.docs[0]
      // console.log(docSnapshot)
      const data = docSnapshot.data()
      const gameData: gameDataProps = {
        date: data.date,
        gameTime: data.gameTime,
        solution: data.solution,
        guesses: data.guesses,
        price: data.price ? data.price : '',
        userId: data.userId,
        redeemed: data.redeemed ? data.redeemed : undefined,
      }
      return { gameData: gameData, documentId: docSnapshot }
    } else {
      const gameData: gameDataProps = {
        date: undefined,
        gameTime: undefined,
        solution: '',
        guesses: [],
        price: '',
        userId: undefined,
        redeemed: undefined,
      }
      return { gameData: gameData }
    }
  } catch (err) {
    console.log(err)
  }
}
