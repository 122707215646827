import { useEffect, useState } from 'react'

import EmailPassword from '../components/pages/auth/EmailPassword'
import LoginButton from '../components/pages/auth/LoginButton'
import useAuth from '../hook/auth'

function Auth() {
  const auth = useAuth()
  const [emailPasswordLogin, setEmailPasswordLogin] = useState(false)
  const [widestButtonWidth, setWidestButtonWidth] = useState(0)
  const [isLoading, setIsLoading] = useState(true)

  // console.log('Soy loading' + loading)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1500)
  }, [])

  const {
    error,
    loading,
    handleGoogleSignIn,
    handleAppleSignIn,
    handleMicrosoftSignIn,
    handleEmailPassRegister,
    handleEmailPassSignIn,
    handleResetPassword,
  } = auth

  const providers = [
    {
      name: 'google',
      onClickFunction: () => handleGoogleSignIn(),
      background: 'bg-white',
      color: 'text-[#757575]',
    },
    {
      name: 'apple',
      onClickFunction: () => handleAppleSignIn(),
      background: 'bg-black',
      color: 'text-white dark:text-white',
    },
    {
      name: 'microsoft',
      onClickFunction: () => handleMicrosoftSignIn(),
      background: 'bg-[#2F2F2F]',
      color: 'text-white',
    },
    /*{ name: 'facebook', onClickFunction: () => handleFacebookSignIn(), background: "bg-[#3b5998]", color: 'text-white' }, */
    // {
    //   name: 'email',
    //   onClickFunction: () => setEmailPasswordLogin(true),
    //   background: 'bg-[#db4437]',
    //   color: 'text-white',
    // },
  ]

  return (
    <main className="flex h-screen bg-neutral-800  ">
      <div className="flex h-[100%] w-[100%] justify-center self-center">
        <div className="flex h-full w-[70%] flex-col justify-center self-center  md:w-[40%] lg:w-[25%] xl:w-[20%] 2xl:w-[17%]">
          <img
            src="/sushiWordLarge.png"
            className="mt-10 w-[90%] self-center object-cover"
            alt="Pluma Icon"
          />
          <h2 className="mt-4 text-center text-xl  tracking-wide text-neutral-300">
            Bienvenido
          </h2>
          <p className="my-5 text-center  text-neutral-300 ">
            Inicie sesión para continuar
          </p>
          <div className="flex h-auto  flex-col self-center ">
            {emailPasswordLogin ? (
              <EmailPassword
                setEmailPasswordLogin={setEmailPasswordLogin}
                handleCreateAccount={handleEmailPassRegister}
                handleSignIn={handleEmailPassSignIn}
                handleResetPassword={handleResetPassword}
                error={error}
              />
            ) : (
              providers.map((provider, index) => (
                <LoginButton
                  key={index}
                  providerName={provider.name}
                  handleSignIn={provider.onClickFunction}
                  background={provider.background}
                  color={provider.color}
                  widestButtonWidth={widestButtonWidth}
                  setWidestButtonWidth={setWidestButtonWidth}
                  isLoading={isLoading || loading}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </main>
  )
}

export default Auth
