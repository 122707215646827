export const priceList = {
  '1': [
    'Balde con 6 Stella Artois',
    'Merchandising Sushiworld',
    '15% de Descuento - con tope de reintegro de $6000',
  ],
  '2': ['Segui Participando', 'Vino Bodega Santa Julia', 'Dos Carpano'],
  '3': [
    'Segui Participando',
    'Segui Participando',
    '2 Latas Santa Julia Chenin Dulce',
  ],
  '4': ['Segui Participando!', 'Segui Participando!', 'Segui Participando!'],
}
