import { LogoutIcon } from '@heroicons/react/outline'

import { HARD_MODE_DESCRIPTION } from '../../constants/strings'
import useAuth from '../../hook/auth'
import { BaseModal } from './BaseModal'
import { SettingsToggle } from './SettingsToggle'

type Props = {
  isOpen: boolean
  handleClose: () => void
  isHardMode: boolean
  handleHardMode: Function
  isDarkMode: boolean
  handleDarkMode: Function
  isHighContrastMode: boolean
  handleHighContrastMode: Function
}

export const SettingsModal = ({
  isOpen,
  handleClose,
  isHardMode,
  handleHardMode,
  isDarkMode,
  handleDarkMode,
  isHighContrastMode,
  handleHighContrastMode,
}: Props) => {
  const auth = useAuth()
  const { handleLogout } = auth
  return (
    <BaseModal title="Configuración" isOpen={isOpen} handleClose={handleClose}>
      <div className="mt-2 flex flex-col divide-y">
        {/* <SettingsToggle
          settingName="Modo Dificil"
          flag={isHardMode}
          handleFlag={handleHardMode}
          description={HARD_MODE_DESCRIPTION}
        /> */}
        <SettingsToggle
          settingName="Modo Oscuro"
          flag={isDarkMode}
          handleFlag={handleDarkMode}
          description="Cambia el modo oscuro para elegir la interfaz con la que te sientas más cómodo."
        />
        {/* <SettingsToggle
          settingName="Alto Contraste"
          flag={isHighContrastMode}
          handleFlag={handleHighContrastMode}
          description={HIGH_CONTRAST_MODE_DESCRIPTION}
        /> */}
        <div className="mt-1 flex h-10  flex-row justify-between">
          <p className="mt-2 self-center  text-center text-gray-500 dark:text-gray-300">
            Cerrar sesion
          </p>
          <button
            type="button"
            className="mt-2  flex w-[20%] items-center justify-center rounded-md border border-transparent bg-neutral-700 text-center  text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-neutral-300 sm:text-sm"
            onClick={() => {
              handleLogout()
            }}
          >
            <LogoutIcon className=" ml-1 h-6 w-6 cursor-pointer dark:stroke-black" />
          </button>
        </div>
      </div>
    </BaseModal>
  )
}
