import { Navigate } from 'react-router-dom'

import LoadingComponent from '../components/Loading'
import useAuth from './auth'

export function ProtectedRoute({ children }) {
  const auth = useAuth()
  const { user, loading } = auth
  // We can to this so it doesnt flick
  if (loading) return <LoadingComponent />

  if (!user) {
    return <Navigate to="/auth" />
  }
  return <>{children} </>
}

export function PublicRoute({ children }) {
  const auth = useAuth()
  const { user, loading } = auth

  if (loading) return <LoadingComponent />
  if (user) {
    return <Navigate to="/" />
  }
  return <>{children} </>
}

// const navigate = useNavigate()

// export function withPublic(Component) {
//   return function WithPublic(props) {
//     const auth = useAuth()
//     if (auth.user) {
//       navigate('/')
//       // The following return is so that the screen doesn't show a flick
//       return (
//         <div className="flex h-screen justify-center self-center bg-black">
//           {/* <img
//             src="/pluma-blanca-white.png"
//             width={45}
//             height={45}
//             className="self-center"
//             alt="Pluma Icon"
//           /> */}
//         </div>
//       )
//     }

//     return <Component auth={auth} {...props} />
//   }
// }

// export function withProtected(Component) {
//   return function WithProtected(props) {
//     const auth = useAuth()
//     if (!auth.user) {
//       navigate('/auth')
//       // The following return is so that the screen doesn't show a flick
//       return (
//         <div className="flex h-screen justify-center self-center bg-black">
//           {/* <img
//             src="/pluma-blanca-white.png"
//             width={45}
//             height={45}
//             className="self-center"
//             alt="Pluma Icon"
//           /> */}
//         </div>
//       )
//     }

//     return <Component auth={auth} {...props} />
//   }
// }
